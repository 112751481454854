import React, { useState } from 'react';
import "./Blog.css";
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';

function Blog() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showTwo, setShowTwo] = useState(false);
    const handleCloseTwo = () => setShowTwo(false);
    const handleShowTwo = () => setShowTwo(true);
    const [showThree, setShowThree] = useState(false);
    const handleCloseThree = () => setShowThree(false);
    const handleShowThree = () => setShowThree(true);
  return (
    <>
     {/* Banner */}
        <div className="aboutBannerContainer">
            <img src="./images/banners/blog.png" alt="Classes" className="aboutBanner" />

      </div>
        <div className='heading'>
                <h4>Our</h4>
                <h1>Blogs</h1>
        </div>
        <section className="blogMainContainer">
            <div className="blogCardsContainer">
                {/* Blog One Start */}
                <div className="blogCardsRow">
                    <Card className="text-center">
                        <Card.Header id="blogsHeader">Languages</Card.Header>
                        <Card.Body>
                            <Card.Title>Why learn languages?</Card.Title>
                            <Card.Text>
                            
                            </Card.Text>
                            <button className="btn_shadow" onClick={handleShow}>Read This Blog..</button>
                        </Card.Body>
                        <Card.Footer className="text-muted">June 2023</Card.Footer>
                    </Card>
                    <Modal size="xl" show={show} onHide={handleClose}>
                        <Modal.Header id="modalHeading" closeButton>
                        <Modal.Title>Why learn languages?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img src="./images/blog/First Blog Photo.png" alt="" className="blogModalMainImg" />
                            <div className="blogModalTexT">
                                <p>I don't know. May be because life is hard and sometimes, we need to express ourselves well to get through it. That's a one-line answer to your million-dollar question in case you don't have the time to read everything I am going to write below.</p>
                                <p>In case you are still reading, let's start. </p>
                                
                                <p>A few days back I met a student who was planning to take the IELTS exam and wanted to get her writing skills evaluated. As always, I posed an essay question just to see her comprehension and ideation skills required to complete the task. As soon as I showed her the question, she said.. 'Ma'am, I don't think I can do it. My English is really bad.' I get this response from students almost every other day so it did not come as a surprise. </p>
                                <p>'It's okay, still try a bit. Your answer does not have to be perfect,' I replied.</p>
                                <p>'Okay, but I'll use my mother tongue too,' she said. I agreed since my point was to check her comprehension and ideation skills at this point.</p>
                                <p>And guys, her understanding of the question and the vocabulary she used to express her points was so good, it really did come as a pleasant surprise. Of course, she expressed herself in her mother-tongue at times but still, the overall understanding of the question and her response to it was truly impressive.</p>
                                <p>The whole experience just reinforced my belief in the idea that it's more often than not the talented ones who are filled with self-doubt and lack confidence in life. </p>
                                <p>Languages, I feel empower us in ways that we don't realize. I mean of course, who learns their mother-tongue? We all acquire it during our childhood and hence the whole learning process happens more like a subconscious activity, not something you do consciously in a classroom setting. But as we grow up in a multilingual world, most of us realize that bilinguals/monolinguals certainly have an edge over monolingual speakers, and so, we too wish to learn a new language or two. Imagine having great, unique thoughts in your mind but since they all exist in your mother-tongue/first language, how do you explain them to people who don't speak your language? You get my point?</p>
                                <p>It wasn't until my English got better in my mid-twenties that I realized I was a more confident and empowered individual that way. I realized how great it felt to be able to express myself well in a language that I feared communicating in till then. When I realized I did not have to go through a sentence three times in my mind just to make sure I wasn't judged in public, I felt genuinely relieved. Because, and I'll be honest with you, as someone who did not have anyone speaking English at home while growing up, as someone who was told, 'tch tch, first learn to speak in English then try talking to us' by a certain classmate while in second standard, or as someone who zoned out multiple times while delivering a presentation in college because she was a highly underconfident 19-year-old, I know very well what it feels like to be judged. I know exactly how it feels when people laugh at your face when you make those silly grammatical mistakes, which I believe you wouldn't if you were a bit more confident, and a little bit better at the language.</p>
                                <p>Now when I look at my students tell me the exact same things, I feel what they go through in their lives. In school, in college or at workplace. Their experiences are similar to mine. And I see in them an ardent desire to become better speakers of English. I talked about English because it's considered a lingua franca in today's world (and also because I teach English 😄) but the point about languages empowering individuals still stands true. It always will.</p>
                                <p>Always wanted to learn Japanese so that you could understand anime better? Go ahead, what are you waiting for?</p>
                                  <p>Wanted to learn French so that you could write a letter to your French pen-pal? Just start already!</p>
                                  <p>Aspire to learn Korean so that you don't need Google translate to understand that song you heard in that K-drama about a magical hotel with super-magical staff? Please start, I did that two years ago.</p>
                                  <p>Want to learn Punjabi so that you can talk to your in-laws? Use YouTube, but start.</p>
                                  <p>Want to learn Sanskrit because you want to read the scriptures in their original language and not translations? See, if my vice-principal in school didn't say 'If you are good at Maths, you can score well in Sanskrit', I’d be able to speak Sanskrit today. </p>
                                  <p>So my point is, let your motivation to learn a new language be anything. </p>
                                  <p>You. Start. Learning. </p>
                                  <p>It's a very rewarding journey, one that will change you for the better.</p>
                                  <p>All the best!</p>
                                  <p>Ashwini</p>
                              </div>
                        </Modal.Body>
                        <Modal.Footer>
                        <button className="btn_shadow"  onClick={handleClose}>
                            Close
                        </button>
                        </Modal.Footer>
                    </Modal>
                    
                </div>
                {/* Blog One End */}  
                {/* Blog Two Start */}
                <div className="blogCardsRow">
                    <Card className="text-center">
                        <Card.Header id="blogsHeader">English</Card.Header>
                        <Card.Body>
                            <Card.Title>Is English difficult to learn?</Card.Title>
                            <Card.Text>
                            {/* With supporting text below as a natural lead-in to additional content. */}
                            </Card.Text>
                            <button className="btn_shadow" onClick={handleShowTwo}>Read This Blog..</button>
                        </Card.Body>
                        <Card.Footer className="text-muted">August 2023</Card.Footer>
                    </Card>
                    <Modal size="xl" show={showTwo} onHide={handleCloseTwo}>
                        <Modal.Header id="modalHeading" closeButton>
                        <Modal.Title>Is English difficult to learn?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img src="./images/blog/Blog two Photo.png" alt="" className="blogModalMainImg" />
                              <div className="blogModalTexT">
                                  <p>Yes. And no. </p>
                                  <p>In my opinion, learning any new language could be a bit difficult. But then again, I’ve seen some people learn a new language so quickly, makes me wonder why it took me so long or why does it take me so long even now as I am still learning a new language. </p>
                                  <p>A tiny percentage of people are truly gifted, you can see them speak 7-8 languages effortlessly. They just learn very fast. You can’t compare yourself to such polyglots because their number is minuscule.</p>
                                  <p>Then there are some who grew up in a multi-lingual setting, where more than two languages were spoken. These people too speak 2-3 languages easily. </p>
                                  <p>Then there are monolinguals, they speak just one language and that is enough for them to get through life.</p>
                                  <p>Now let me talk about people who aspire to learn new languages but struggle when it comes to actually learning one.</p>
                                  <p>Many people who know me know that I once flunked a certificate course in French. This was 13 years ago. It made me believe I was not made for foreign languages. I mean how was it that my classmates could answer so well but I couldn’t a make few basic sentences in French? Why I failed French is a story for another blog.</p>
                                  <p>Anyway, during college we learnt about the ‘Critical Period Hypothesis’. Here, I will try to paraphrase what it means in simple terms. The theory states that people can go on to speak any given language really well if they are exposed to it quite early in life. Let’s say before they reach 12 or 13. You must have seen it around you too, children can learn to speak any language well, be it Chinese or Sanskrit, if they are exposed to it very early in life. Compare this to some adult you know who started learning a new language in their 30s or 40s. The theory does not state adult speakers can’t speak well or they can’t be fluent, for God knows the number of really fluent foreign language speakers is big, the theory simply states that it becomes difficult for one to achieve native-level proficiency in a language if you start later in life. </p>
                                  <p>That’s one reason why I believe learning a new language isn’t very easy.</p>
                                  <p>Another reason why learning English or any foreign language could seem difficult is because language courses are very demanding. Learning a new language requires genuine effort and a good amount of dedication. It requires you to practice in the target language daily, learn a new script if needed, learn new vocabulary and various grammar patterns, take tests regularly, the list goes on. Okay it does not, but still, there is much that you need to do if you want to learn a new language. Many fail at this stage and they give up. I mean work for 8 hours, commute, find time to exercise, social media, socialize, stay sane and whatnot!</p>
                                  <p>If you study from day one, cut some time on social media, watch some videos in the target language while commuting, wake up an hour early to study or revise some vocabulary, work on weekdays and try learning your foreign language on weekends, you can do it. Learning won’t suddenly become easy but it will surely not feel as intimidating as before. And once you start getting better at a language, you won’t have to invest as much time in the future, you can gradually reduce the time you invest in your learning. I can assure you it gets easier with practice. </p>
                                  <p><span style={{ fontWeight: 'bold', fontStyle: "italic" }}>No language is so difficult that you can’t learn it.</span> Surely not English otherwise 1.5 billion people wouldn’t be speaking it. This will sound very cliché but every time you feel like giving up, remember why you chose to study English in the first place. Visualize yourself confidently speaking English. Imagine how that would feel.<br />And continue.</p>
                                  <p>If you failed English in school, tell yourself, ‘So, what? I am allowed to start again’.</p>
                                  <p>If you quit an English class once because you did not like the teacher, find a new one and start afresh.</p>
                                  <p>If you cannot afford to join a class, learn on YouTube. </p>
                                  <p>In the end, do it for yourself, and do it only if you genuinely feel the need to learn English, otherwise what’s the point?</p>
                                  <p>All the best!</p>
                                  <p>Ashwini</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        <button className="btn_shadow"  onClick={handleCloseTwo}>
                            Close
                        </button>
                        </Modal.Footer>
                    </Modal>
                    
                </div>
                  {/* Blog Two End */} 
                  {/* Blog Three Start */}
                <div className="blogCardsRow">
                    <Card className="text-center">
                        <Card.Header id="blogsHeader">Confidence</Card.Header>
                        <Card.Body>
                            <Card.Title>Confidence, the golden key.</Card.Title>
                            <Card.Text>
                            {/* With supporting text below as a natural lead-in to additional content. */}
                            </Card.Text>
                            <button className="btn_shadow" onClick={handleShowThree}>Read This Blog..</button>
                        </Card.Body>
                        <Card.Footer className="text-muted">August 2023</Card.Footer>
                    </Card>
                    <Modal size="xl" show={showThree} onHide={handleCloseThree}>
                        <Modal.Header id="modalHeading" closeButton>
                        <Modal.Title>Confidence, the golden key.</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img src="./images/blog/Blog 3.png" alt="" className="blogModalMainImg" />
                              <div className="blogModalTexT">
                                  <p>I feel that nobody is born underconfident. We all tend to lose our confidence somewhere along the way. Others putting us down while growing up, it could be our siblings, parents, friends, relatives or classmates. People do and say things that lower our confidence and we don’t even realize it until one day we wake to find we are afraid to do something that others around us do without a second thought. </p>
                                  <p>Of course, I don’t mean to say it’s only other people who lower our confidence. It could also be our circumstances. I mentioned in one of my previous blogs that I was a very underconfident 19-year-old in college. I had decided to change my stream to Arts because I was deeply unhappy studying Physics, Chemistry and Maths. Moreover, I chose to move to a big city to do what was simply called a ‘BA degree’ which according to some people around me was anyway, not going to give me a job in the future, so not only was it a wrong decision in people’s eyes in terms of career prospects but also, I was wasting my parents’ money by moving to a big city to pursue a degree that had no real-world benefits. Then to top it all, I secured admission in one of the best colleges in Pune and you know what? I felt out of place there. For quite some time. I carried a lot of weight from where I came, the weight of expectations, the self-doubt of whether I was doing the right thing? Whether I was really wasting my parents’ hard-earned money, was it a mistake, will I be accepted in this circle, will this big city accept me, will I ever be able to make friends here? Blah blah blah.</p>
                                  <p> I had the best three years of my life in college. By this I am not trying to say I did not have my bad days or that I did not struggle to find my feet in a new place amongst new people. For God knows how long it took me to feel comfortable there. But what I do feel is that college instilled in me a confidence that helped me do away with a lot of my self-doubt and inhibitions. It made me stronger. It gave me wings to dream and see those dreams come to life. A smile from a stranger in the college campus, new friendships, the feeling of being accepted, being able to speak in front of 40 people-knowing well enough that you could be wrong, volunteering for college fests, getting to know new people, scoring well in academics, teachers congratulating you for doing well, you finally knowing that what people thought was a wrong decision was not so wrong after all.. all these things gave me a confidence that was worth more than any monetary investments or returns.</p>
                                  <p>There will always be people who pull you down, who lower your confidence. Make you feel small. You have to find your way to get that dose of confidence you need to excel in life. </p>
                                  <p>Two years back I was a summer intern at the Living Tongues Institute for Endangered Languages and I’ll never forget what Ms. Anna Luisa Daigneault, program director at LT, told our entire cohort about confidence.</p>
                                  <p>She said, ‘Don’t assume the next person (interviewer in this case) knows anything about you. Don’t let them know of your self-doubt, pretend to be Beyoncé for some time’. </p>
                                  <p>Now you can replace Beyoncé with any person you find extremely confident. It could be a real person or it could be a fictional character from an anime you like or the book you love. Find someone and pretend to be them when things get tough and you find your confidence shaking. </p>
                                  <p>And then go, chase your dreams. 😊</p>
                                  <p>All the best!</p>
                                  <p>Ashwini</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                        <button className="btn_shadow"  onClick={handleCloseThree}>
                            Close
                        </button>
                        </Modal.Footer>
                    </Modal>
                    
                </div>
                {/* Blog One End */} 
            </div>
        </section>
    </>
  )
}

export default Blog