import React from 'react';
import "./HomeFaqSection.css";
import Accordion from 'react-bootstrap/Accordion';

function HomeFaqSection() {
  const AccordionData = [
    {
      id:0,
      title: "Why should I learn English?",
      answer:"We know that the world is a multilingual place and that every language is beautiful and useful in its own way. Having said that, we should also realize that English is the lingua franca of today’s world. It is the most widely spoken language in the world with over 1.4 billion speakers. It is no longer a language that only belongs to a small island country that once colonized major parts of the world. Today, it is a language that belongs to us non-native speakers of English as much as it belongs to them. Be it at workplace or in schools and colleges, English is widely used in all spheres of life, hence learning it becomes important if we need to successfully express ourselves in settings where speakers do not share a common language. "
    },
    {
      id:1,
      title: "I studied English in school but I am still not fluent in the language, why is that so?",
      answer:"There are two main reasons for this, first being, for one to become a fluent speaker of a language one has to also use the language one learns in school outside of the school. Simply knowing rules of grammar will not improve fluency if you don’t practice speaking in that language outside of the classroom. Another reason why people fail to speak English well is they never really invested quality time and energy into studying the language. They knew they could pass school and college exams with what little they knew, it’s only when they step out of their college and enter workplace that they realize they can’t speak the language well."
    },
    {
      id: 2,
      title: "Do you provide offline class?",
      answer:"No, at the moment we provide only online classes. The post-pandemic world has taught us that one need not always step out to learn something, we can learn and acquire new skills equally well from the comfort of our own homes and honestly, why step out in the traffic and waste both time and fuel when you can nicely e-learn at home? "
    },
    {
      id: 3,
      title: "Are online classes effective in learning a foreign language?",
      answer:"We feel both online and offline classes are equally effective and with changing times, more and more people prefer online classes vis-à-vis offline ones for their numerous benefits, some of which we mentioned above. Having taught online for over two years now, we can safely say online teaching is just as helpful to students as offline teaching."
    },
    {
      id: 4,
      title: "What platform do you use to conduct the class?",
      answer:"We use Google Meet for all our classes."
    },
    {
      id: 5,
      title: "Will my vocabulary improve after joining the class?",
      answer:" Of course, one of our major areas of focus is vocabulary development. We make sure you understand and learn and new words every day. "
    },
    {
      id: 6,
      title: "Will I achieve native level proficiency by the end of your course?",
      answer:"We all learn languages at a different pace, some learn very quickly, some take a long time to gain proficiency. What we do is equip you with the tools to learn a language well, we help you with your grammar, vocabulary and fluency development, ideation skills, pronunciation, writing skills, etc. Once the course ends, we hope you will be a much better speaker of English, a more confident, empowered individual than you imagined. Having said that, language learning is a life long process so we recommend students to continue doing so and not let your learning be limited to the classroom."
    },
    {
      id: 7,
      title: "How many students do you have in a single batch?",
      answer:"The number varies but at no point will the number ever be more than 10. We think that learning in small groups of 7-10 is more effective than learning in large groups when it comes to foreign language learning."
    },
    {
      id: 8,
      title: "What is the fee of the course?",
      answer:"The fee depends on the course. The fee for our comprehensive three-month long English Language course is 6000 INR."
    },
    {
      id: 9,
      title: "Can I pay the fee in two installments?",
      answer:"No, you need to pay the whole fee before you enroll for the class. But we do make an exception to that if we find a student comes from a financially vulnerable background."
    },
    {
      id: 10,
      title: "How can I pay the fee?",
      answer:"You can do a direct bank transfer or use either Gpay or PhonePe to pay the fee."
    }
  ]
  return (
    <>
        <section>
            <div className='heading'>
                <h4>Some frequently asked questions</h4>
                <h1>FAQs</h1>
            </div>
            {/* Accordion Start */}
        <Accordion defaultActiveKey="0">
          {AccordionData.map((item) => {
            return <Accordion.Item  eventKey={item.id}>
              <Accordion.Header id="AccordionHeading"  ><span style={{fontWeight:"bold"}}>{item.title}</span></Accordion.Header>
        <Accordion.Body>
         {item.answer}
        </Accordion.Body>
      </Accordion.Item>
          })}
      
    </Accordion>
        </section>
    </>
  )
}

export default HomeFaqSection