import React from 'react';
import "./App.css";
import {Routes, Route} from "react-router-dom";
import Header from './FunctionalComponents/Header/Header.jsx'
import Home from './FunctionalComponents/HomeContent/Home.jsx';
import About from './FunctionalComponents/AboutContent/About.jsx';
import Classes from "./FunctionalComponents/Classes/Classes.jsx";
import FreeResources from './FunctionalComponents/FreeResources/FreeResources.jsx'
import Blog from './FunctionalComponents/Blog/Blog.jsx';
import Contact from './FunctionalComponents/Contact/Contact.jsx';
import Footer from './FunctionalComponents/Footer/Footer';

const App = () => {
  return (
    <>
    <Header/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/classes" element={<Classes/>} />
        <Route path="/freeResources" element={<FreeResources/>} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/contact" element={<Contact/>} />
      </Routes>
    <Footer/>      
    </>
  )
}

export default App

