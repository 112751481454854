import React from 'react';
import './Home.css';
import HomeCardCarousel from './HomeCardCarousel.jsx';
import HomeFaqSection from './HomeFaqSection';
import Carousel from "nuka-carousel";
import { GrNext, GrPrevious } from "react-icons/gr";

const Home = () => {
  return (
    <>
      <div className="homeMainContainer">
        <section className="homeLandingSection">
          <div className="homeLandingSectionLeft">
            <div className="homeLandingSectionLeftTop">
              <img src="./images/home/homeLogo.png" alt="Logo" className="homeLandingSectionLeftTopImg" />
            </div>
            <div className="homeLandingSectionLeftBottom">
              <h1 className="homeLandingSectionLeftBottomH1">
                Wordsworth's Daffodils
              </h1>
              <h3 className="homeLandingSectionLeftBottomH3">
                Learn English the fun way..
              </h3>
            </div>
          </div>
          <div className="homeLandingSectionRight">
            <img src="./images/home/home.jpg" alt="" className="homeLandingSectionRightImg" />
          </div>
        </section>
        <section>
          <HomeCardCarousel/>
        </section>
        <section>
          <HomeFaqSection/>
        </section>
        {/* Testimonials */}
        <section>
          <div className="testimonyMainContainer">
          <div className='heading'>
                <h4>See what our previous students have to say about us</h4>
                <h1>Testimonials</h1>
            </div>
            <Carousel renderCenterLeftControls={({ previousSlide }) => (
    <button className="homeNukaButtons" onClick={previousSlide}>
      <GrPrevious />
    </button>
  )}
  renderCenterRightControls={({ nextSlide }) => (
    <button className="homeNukaButtons" onClick={nextSlide}>
      <GrNext />
    </button>
  )}>
              <div className="tesimonialContent">
                <div className="tesimonialContentWrapper">
                  <img src="./images/home/testimonialBG1.jpg" alt="" 
                  className="tesimonialContentBgImg" />
                  <div className="tesimonialContentTextContainer">
                    <p className="tesimonialContentTextDetails">The instructor was highly knowledgeable and experienced, creating a welcoming and encouraging atmosphere in the class. She was patient in explaining concepts and always available to clarify any doubts. Her feedback and individual attention greatly contributed to my progress.</p>
                    <p className="tesimonialContentTextName">~ Shraddha ~</p>
                    <p className="tesimonialContentTextAge">Age - 23</p>
                  </div>
                </div>
              </div>
              <div className="tesimonialContent">
                <div className="tesimonialContentWrapper">
                  <img src="./images/home/testimonialBG2.jpg" alt="" 
                  className="tesimonialContentBgImg" />
                  <div className="tesimonialContentTextContainer">
                    <p className="tesimonialContentTextDetails">
                    Age doesn't matter when you drive to succeed in your life. At the age of 40 I joined 'Wordsworth's Daffodils' for basic English grammar and communication class. 
I must say Ashwini is a very supportive and a friendly tutor.  She is always ready to understand our queries and solve our questions. After our class I can see the difference in my English communication skills as well as a lot  of  improvement in comprehension skills. </p>
                    <p className="tesimonialContentTextName">~ Ashwini M ~</p>
                    <p className="tesimonialContentTextAge">Age - 40</p>
                  </div>
                </div>
              </div><div className="tesimonialContent">
                <div className="tesimonialContentWrapper">
                  <img src="./images/home/testimonialBG3.jpg" alt="" 
                  className="tesimonialContentBgImg" />
                  <div className="tesimonialContentTextContainer">
                    <p className="tesimonialContentTextDetails"> Ashwini is an awesome tutor! She is really confident, warm, accessible, enthusiastic and caring. The students  are comfortable with her and share their problems or concerns with her freely. She is a very fluent speaker of English. Moreover, she takes a lot of  effort in each one of her classes. She also makes her classes super organised. This makes it way easier to learn the language. I highly recommend her as your English tutor!</p>
                    <p className="tesimonialContentTextName">~ Manasi ~</p>
                    <p className="tesimonialContentTextAge">Age - 40</p>
                  </div>
                </div>
              </div>
              <div className="tesimonialContent">
                <div className="tesimonialContentWrapper">
                  <img src="./images/home/testimonialBG1.jpg" alt="" 
                  className="tesimonialContentBgImg" />
                  <div className="tesimonialContentTextContainer">
                    <p className="tesimonialContentTextDetails">My experience with this class was awesome and it's proved more helpful for me because now I'm a more confident person while making drafts and also presenting arguments in English at work. I'm really happy about my improvement. Teaching method was really good and easy to understand. What I liked the most was the friendly atmosphere.The course was very well structured and affordable. Ashwini Ma'am has an ebullient personality and I'm glad to have found such a kind teacher.</p>
                    <p className="tesimonialContentTextName">~ Ketaki ~</p>
                    <p className="tesimonialContentTextAge">Age - 24</p>
                  </div>
                </div>
              </div>
              <div className="tesimonialContent">
                <div className="tesimonialContentWrapper">
                  <img src="./images/home/testimonialBG2.jpg" alt="" 
                  className="tesimonialContentBgImg" />
                  <div className="tesimonialContentTextContainer">
                    <p className="tesimonialContentTextDetails">Joining Wordsworth's Daffodils improved my English very much. It has helped me to communicate well in English with my school teachers and friends. My grammar improved greatly. </p>
                    <p className="tesimonialContentTextName">~ Manaswi ~</p>
                    <p className="tesimonialContentTextAge">Age - 15</p>
                  </div>
                </div>
              </div>
              <div className="tesimonialContent">
                <div className="tesimonialContentWrapper">
                  <img src="./images/home/testimonialBG3.jpg" alt="" 
                  className="tesimonialContentBgImg" />
                  <div className="tesimonialContentTextContainer">
                    <p className="tesimonialContentTextDetails">My experience at Wordsworth's Daffodils was exceptional. The tutor was amazing and solved all the doubts I had. My knowledge of Grammar increased a lot due to the class and the teaching method was also excellent. </p>
                    <p className="tesimonialContentTextName">~ Dev ~</p>
                    <p className="tesimonialContentTextAge">Age - 13</p>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </section>
      </div>
    </>
  )
}

export default Home
