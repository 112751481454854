import React from 'react';
import './About.css'

const About = () => {
  return (
    <>
      {/* Banner */}
        <div className="aboutBannerContainer">
            <img src="./images/banners/About Page Final.png" alt="Classes" className="aboutBanner" />

      </div>
      <section>
        <div className='heading'>
                <h4>About</h4>
                <h1>Trainer</h1>
        </div>
        <article className="aboutMainSection">
          
          <div className="aboutImgContainer">
            <img style={{border:"1px solid yellow"}} src="./images/home/AP_gradient.png" alt="WD Logo" className="aboutImg" />
          </div>
          <div className="aboutText">
            <p>Hi there,</p>
            <p>    
I am Ashwini, an English teacher and IELTS trainer from Pune, India. I graduated in English from Fergusson College, Pune and later pursued my Master’s in Linguistics from the prestigious Deccan College in Pune. I also qualified the NET for Linguistics after college and completed another Master’s, this time in English, from SPPU, Pune. Additionally,  I got myself certified as a TEFL teacher from Asian College of Teachers in 2019. For two years I worked with a very popular IELTS coaching platform based in Bengaluru. Over the years, I have helped many students speak English well. While working as an IELTS trainer, I helped many students achieve their target bands in the IELTS exam. 

            </p>
            <p>Wordsworth’s Daffodils is my English class and it aims to help students empower themselves. Whether you want to speak English well at your workplace, or just improve your vocabulary, crack the IELTS for further studies or immigrate to an English-speaking country, I am here to help. </p>
          </div>
        </article>
      </section>
      <section>
        <div className='heading'>
                <h4>About</h4>
                <h1>Wordsworth's Daffodils</h1>
        </div>
        <article className="aboutMainSection">
          <div className="aboutImgContainer">
            <img src="./images/Logo.jpg" alt="WD Logo" className="aboutImg" />
          </div>
          <div className="aboutText">
            <p>
            Wordsworth's Daffodils began with an earnest desire to make English language learning both, empowering and fun for its learners. We understand that learning any foreign language comes with its unique set of challenges and tackling them can be a lonely journey if you don't find the right guidance and right people to practice with.
            </p>
            <p> At Wordsworth's Daffodils, we help our students face their fear of English language, one day at a time. Like the old adage, journey is more important than the destination,  we want our students to enjoy the language learning process everyday and not just stay focused on the end goal of being fluent in English one day. As some nice person once said, 'Little by little one travels far.'</p>
          </div>
        </article>
      </section>
      <section className="AboutRegistrationCertificateContainer">
        <img className="AboutRegistrationCertificate" src="./images/home/aboutRegistration.jpg" alt="Registration Certificate" />
      </section>
    </>
  )
}

export default About
