import React from 'react';
import './FreeResources.css';
import Card from 'react-bootstrap/Card';
import Articles from './pdf/Articles in English_WD.pdf';
import British from './pdf/British vs. American Spellings_WD.pdf';
import Irregular from './pdf/Irregular Verbs_WD.pdf';
import Phrasal from './pdf/_Phrasal Verbs_WD.pdf';
import Regular from './pdf/Regular Verbs_WD.pdf';
import Homophones from './pdf/Homophones_WD.pdf';
import Idioms from './pdf/Idioms_WD.pdf';
import Confusing from './pdf/Confusing Words_WD.pdf'


function FreeResources() {
    
  return (
    <>
        {/* Banner */}
        <div className="aboutBannerContainer">
            <img src="./images/banners/FR.png" alt="Classes" className="aboutBanner" />

      </div>
        <section className="freeMainContainer">
        <div className='heading'>
                <h4>Our</h4>
                <h1>Free Resources</h1>
        </div>
        <div className="freeCardContainer">
                  <div className="freeCardContentRow">                      
                        <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>Articles</Card.Title>
                              <Card.Text style={{ height: "7rem"}}>
                                Do you keep on getting confused over whether to use ‘a/an’ or ‘the’? Go through this small lesson to clear your doubts.
                              </Card.Text>
                                  <Card.Link className="freeCardLinks" href={Articles} download="Articles in English" rel="noreferrer">Download PDF</Card.Link>
                                
                            </Card.Body>
                      </Card>
                      <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>British vs. American English</Card.Title>
                                <Card.Text style={{ height: "7rem"}}>
                                Not sure if it’s ‘Defence’ or ‘Defense’? Find out more such variations in American and British English here.
                              </Card.Text>
                                  <Card.Link className="freeCardLinks" href={British} download="British vs American English" rel="noreferrer">Download PDF</Card.Link>
                                
                            </Card.Body>
                      </Card>
                      <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>Irregular Verbs</Card.Title>
                                <Card.Text style={{ height: "7rem"}}>
                                Didn’t know that the past participle form of choose is chosen? Go through the list to find out more such irregular verbs in English.
                              </Card.Text>
                                  <Card.Link className="freeCardLinks" href={Irregular} download="Irregular Verbs" rel="noreferrer">Download PDF</Card.Link>
                                
                            </Card.Body>
                      </Card>
                      <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>Phrasal Verbs</Card.Title>
                                <Card.Text style={{ height: "7rem"}}>
                               Not sure what are phrasal verbs? Here’s a quick guide.
                              </Card.Text>
                                  <Card.Link className="freeCardLinks" href={Phrasal} download="Phrasal Verbs" rel="noreferrer">Download PDF</Card.Link>
                                
                            </Card.Body>
                      </Card>
                      <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>Regular Verbs</Card.Title>
                                <Card.Text style={{ height: "7rem"}}>
                               Here’s a list of some commonly used regular verbs in English.
                              </Card.Text>
                                  <Card.Link className="freeCardLinks" href={Regular} download="Regular Verbs" rel="noreferrer">Download PDF</Card.Link>
                                
                            </Card.Body>
                      </Card>
                      <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>Homophones</Card.Title>
                                <Card.Text style={{ height: "7rem"}}>
                               Never heard of homophones? Check out this small lesson!
                              </Card.Text>
                                  <Card.Link className="freeCardLinks" href={Homophones} download="Homophones" rel="noreferrer">Download PDF</Card.Link>
                                
                            </Card.Body>
                      </Card>
                      <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>Idioms</Card.Title>
                                <Card.Text style={{ height: "7rem"}}>
                               Ability to understand and use idioms is extremely important when learning a new language. Here are some commonly used idioms in English.
                              </Card.Text>
                                  <Card.Link className="freeCardLinks" href={Idioms} download="Idioms" rel="noreferrer">Download PDF</Card.Link>
                                
                            </Card.Body>
                      </Card>
                      <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title>Confusing words</Card.Title>
                                <Card.Text style={{ height: "7rem"}}>
                               Still not sure if it’s ‘there or their or they’re?’ Here’s a quick guide to understand more such confusing words.
                              </Card.Text>
                                  <Card.Link className="freeCardLinks" href={Confusing} download="Confusing words" rel="noreferrer">Download PDF</Card.Link>
                                
                            </Card.Body>
                        </Card>
                </div>
        </div>
        </section>
    </>
  )
}

export default FreeResources