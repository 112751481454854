import React, { useState } from 'react';
import "./Classes.css";
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom'; 
import English from './pdf/English Class Broucher.pdf';
import Ielts from './pdf/IELTS Class Broucher.pdf'

function Classes() {
  const [showOne, setShowOne] = useState(false);
  const handleCloseOne = () => setShowOne(false);
  const handleShowOne = () => setShowOne(true);

  const [showTwo, setShowTwo] = useState(false);
  const handleCloseTwo = () => setShowTwo(false);
  const handleShowTwo = () => setShowTwo(true);

  const [showThree, setShowThree] = useState(false);
  const handleCloseThree = () => setShowThree(false);
  const handleShowThree = () => setShowThree(true);

  return (
    <>
        {/* Banner */}
        <div className="aboutBannerContainer">
            <img src="./images/banners/Classes We Offer.png" alt="Classes" className="aboutBanner" />

      </div>
        <div className='heading'>
                <h4>Classes/Courses</h4>
                <h1>Offered</h1>
        </div>
        <div className="classesMainContainer">
          {/* Card One Start */}
        <Card style={{ width: '18rem', marginBottom:'2rem' }}>
          <Card.Img variant="top" src="./images/home/Learn English_5.png" />
          <Card.Body>
            <Card.Title>English Classes</Card.Title>
            <Card.Text style={{ height: "5rem"}}>
              Our most popular English language course.
            </Card.Text>
            
            <button className="btn_shadow" onClick={handleShowOne}>Know More..</button>
          </Card.Body>
        </Card>

        {/* Modal Start */}
        <Modal size="lg" show={showOne} onHide={handleCloseOne}>
        <Modal.Header closeButton>
          <Modal.Title>English Classes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBodyImgContainer">
            <img src="./images/home/Learn English_5.png" alt="About" className="modalBodyImg" />
          </div>         
         <div className="modalBodyTextContainer">
          <p>We offer a comprehensive three-month long English
language course that will help students not just with grammar and fluency
but also with building vocabulary and developing their ideation skills. This is
our most popular course and we can assure you that you will enjoy this
course thoroughly.</p>
          <p>We offer 60 sessions that span across 3 months, 1 hour for 5 days a week.</p>
         </div>
        </Modal.Body>
        <Modal.Footer>
          <a href={English} Download="English Class Brochure Wordsworth's Daffodils" className="btn_shadow downloadBrouchreButton">
            Download Brochure
          </a>
          <button className="btn_shadow" >
           <Link className='contactUsButton' to='/contact'>Contact</Link>
          </button>
        </Modal.Footer>
      </Modal>
        {/* Card One End */}
          {/* Card Two Start */}
          <Card style={{ width: '18rem', marginBottom:'2rem' }}>
          <Card.Img variant="top" src="./images/home/IELTS_Academic2.png" />
          <Card.Body>
            <Card.Title>IELTS Academic</Card.Title>
            <Card.Text style={{ height: "5rem"}}>
              Plan to study abroad? This course will help students to
clear their IELTS in one go.
            </Card.Text>
            <button className="btn_shadow" onClick={handleShowTwo}>Know More..</button>
          </Card.Body>
        </Card>

        {/* Modal Start */}
        <Modal size="lg" show={showTwo} onHide={handleCloseTwo}>
        <Modal.Header closeButton>
          <Modal.Title>IELTS Academic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBodyImgContainer">
            <img src="./images/home/IELTS_Academic2.png" alt="Academic" className="modalBodyImg" />
          </div>         
         <div className="modalBodyTextContainer">
          <p>Many universities abroad require you to provide a proof
of your English language proficiency by taking tests like IELTS or PTE.</p>
          <p>We have a number of courses for students who wish to take the IELTS
Academic test based on the time they have and the level they are at right
now. For more details, feel free to call us on <a href="tel:+919689275856">+919689275856</a></p>
         </div>
        </Modal.Body>
        <Modal.Footer>
          <a href={Ielts} Download="IELTS Class Brochure Wordsworth's Daffodils" className="btn_shadow downloadBrouchreButton">
            Download Brochure
          </a>
          <button className="btn_shadow" >
           <Link className='contactUsButton' to='/contact'>Contact</Link>
          </button>
        </Modal.Footer>
      </Modal>
        {/* Card Two End */}
        {/* Card Three Start */}
        <Card style={{ width: '18rem', marginBottom:'2rem' }}>
          <Card.Img variant="top" src="./images/home/IELTS_General.png" />
          <Card.Body>
            <Card.Title>IELTS General</Card.Title>
            <Card.Text style={{ height: "5rem"}}>
              Aspire to live and work in an English-speaking country?
This course will help you score the elusive 8777.
            </Card.Text>
            <button className="btn_shadow" onClick={handleShowThree}>Know More..</button>
          </Card.Body>
        </Card>

        {/* Modal Start */}
        <Modal size="lg" show={showThree} onHide={handleCloseThree}>
        <Modal.Header closeButton>
          <Modal.Title>IETLS General</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modalBodyImgContainer">
            <img src="./images/home/IELTS_General.png" alt="" className="modalBodyImg" />
          </div>         
         <div className="modalBodyTextContainer">
          <p>If you wish to live and work in an English-speaking
country, you already know how important it is to score 8777 in the IELTS
test.</p>
          <p>With regular mock tests and quality feedback, we assure you, you too can
score 8777. We have a number of courses for students who wish to take
the IELTS general training test based on the time they have and the level
they are at right now. For more details, feel free to call us on <a href="tel:+919689275856">+919689275856</a></p>
         </div>
        </Modal.Body>
        <Modal.Footer>
          <a href={Ielts} Download="IELTS Class Brochure Wordsworth's Daffodils" className="btn_shadow downloadBrouchreButton">
            Download Brochure
          </a>
            
          <button className="btn_shadow" >
           <Link className='contactUsButton'  to='/contact'>Contact</Link>
          </button>
        </Modal.Footer>
      </Modal>
        {/* Card Three End */}
        </div>
    </>
  )
}

export default Classes