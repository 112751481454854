import React from 'react';
import "./Contact.css"

function Contact() {
  return (
    <>
        <div className="contactMainContainer" id="contactUs">
            <div className="contactGridWrapper">
                <div className="contactGridContent">
                    <img src="./images/home/contact.jpg" alt="Contact" className="contactImage" />
                </div>
          <div className="contactGridContentRight">
            <div className='contactUsCard'>
              <p>Email us at <br/><a href="mailTo:ashwini@wordsworthsdaffodils.in">ashwini@wordsworthsdaffodils.in</a> </p>
            </div>
            <div className='contactUsCard'>
              <p>Call Us at: <br/><a href="tel:+919689275856">+919689275856</a></p>
            </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Contact