import React from 'react';
import "./Footer.css";
import { Link } from 'react-router-dom'; 
import { GrInstagram  } from "react-icons/gr";

function Footer() {
  return (
    <>
        <div>
            <div className="footerMainContainer">
              <div className="footerWrapper">
                <div>&nbsp;</div>
                <div className="footerGridContentsOne">
                  <h5>Office</h5>
                  <address className="address">                    
                Wordsworth's Daffodils,<br />
                Model Colony,<br />
                    Shivaji Nagar,<br/>                    
                    Pune - 411016<br/>
                India <br/>
                Write us on <a href="mailto:ashwini@wordsworthsdaffodils.in" className="addressEmail">ashwini@wordsworthsdaffodils.in</a>.<br/>
                Call us on <a href="tel:+919689275856">+919689275856</a><br />
                  </address>
                </div>
                <div className="footerGridContentsTwo">
                  <ul>
                  <li>
                <Link id="footerGridContentsTwoli" to='/'>Home</Link>
              </li>
              <li>
                <Link id="footerGridContentsTwoli" to='/about'>About</Link>
              </li>
              <li>
              <Link id="footerGridContentsTwoli" to='/classes'>Classes</Link>
              </li>
              <li>
              <Link id="footerGridContentsTwoli" to='/freeResources'>Free Resources</Link>
              </li>
              <li>
                <Link id="footerGridContentsTwoli" to='/blog'>blog</Link>
              </li>
              <li>
                <Link id="footerGridContentsTwoli" to='/contact'>contact</Link>
              </li>
                  </ul>
                </div>
                <div className="footerGridContentsThree">
                  {/* <div className="btn_shadow footerFacebookIcon">
                  <GrFacebookOption />
                  </div> */}
              <a href="https://www.instagram.com/wordsworthsdaffodils/" rel="noreferrer" target="_blank" style={{textDecoration:"none"}}>
                <div className="btn_shadow" >
                  <GrInstagram/>
                </div>
              </a>
                                  
                  
                </div>
              </div>
            </div>
        </div>
    </>
  )
}

export default Footer