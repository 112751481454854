import React from 'react';
import "./HomeCardCarousel.css"
import Carousel from "nuka-carousel"
import { GrNext, GrPrevious } from "react-icons/gr";
import Card from 'react-bootstrap/Card';

const HomeCardCarousel = () => {
  const CardData = [
    {
      cardImg: "./images/home/newCarousel/Afraid Final.png",
      cardDescription:"Wanted to learn English but were too afarid to try?"
    },
    {
      cardImg: "./images/home/newCarousel/Bored.png",
      cardDescription:"Tried to learn English but did not like the class..?"
    },
    {
      cardImg: "./images/home/newCarousel/Grammar.jpg",
      cardDescription:"Tried learning English once but thought grammar was a bit difficult..?"
    },
    {
      cardImg: "./images/home/newCarousel/Fluency.png",
      cardDescription:"Can understand English well but can't speak fluently..?"
    },
    {
      cardImg: "./images/home/newCarousel/Missed Opportunities.png",
      cardDescription:"Have you missed opportunities because someone thought your English was not up to the mark..?"
    },
    {
      cardImg: "./images/home/newCarousel/No Time.jpg",
      cardDescription:"Always wanted to learn English but never had the time..?"
    },
    {
      cardImg: "./images/home/newCarousel/Pronunciation.png",
      cardDescription:"Have you been struggling with the correct pronunciation..?"
    },
    {
      cardImg: "./images/home/newCarousel/Lack Confidence Final.png",
      cardDescription:"Lack the confidence to speak English in a public setting..?"
    },
    {
      cardImg: "./images/home/newCarousel/Features6.jpg",
      cardDescription:"Feel it's too late to learn?? Here's a secret.. it's never too late to learn and get better at a language, especially if it's English."
    }
  ]
  return (
    <>
    <div className='heading'>
                <h4>If any of the below-mentioned problems are your problems, then..</h4>
                <h1>You are in the right place</h1>
    </div>
        <section className="cardCarouselContainer">
            <Carousel style={{paddingLeft:"5%"}}
          autoplay={true}
          autoplayInterval={3000}
              wrapAround={true} 
          slidesToShow={3} 
          pauseOnHover={true}
          dragging={true}
              renderCenterLeftControls={({ previousSlide }) => (
              <button className="homeNukaButtons" onClick={previousSlide}>
                <GrPrevious />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button className="homeNukaButtons" onClick={nextSlide}>
                <GrNext />
              </button>
          )}>
          {CardData.map((item) => {
            return <Card style={{ width: '18rem' }}>
                <Card.Img variant="top" className="homeFeaturesCardImg" src={item.cardImg} />
                <Card.Body  className="homeCarouselCardText" >
                  
                  <Card.Text >

                    {item.cardDescription}
                  </Card.Text>
                </Card.Body>
              </Card>
          })}             
                
            </Carousel>           
        </section>
        <section className="cardCarouselContainerSmallscreen">
        <Carousel style={{ paddingLeft: "2rem" }}
          autoplay={true}
          autoplayInterval={3000}
              wrapAround={true}
          pauseOnHover={true}
          dragging={true}
              slidesToShow={1} 
              renderCenterLeftControls={({ previousSlide }) => (
              <button className="homeNukaButtons" onClick={previousSlide}>
                <GrPrevious />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button className="homeNukaButtons" onClick={nextSlide}>
                <GrNext />
              </button>
          )}>
          {CardData.map((item) => {
            return <Card style={{ width: '18rem' }}>
                <Card.Img variant="top" className="homeFeaturesCardImg" src={item.cardImg} />
                <Card.Body  className="homeCarouselCardText" >
                  
                  <Card.Text >
                    
                    {item.cardDescription}
                  </Card.Text>
                </Card.Body>
              </Card>
          })}            
                
            </Carousel> 
        </section>

    </>
  )
}

export default HomeCardCarousel;